// extracted by mini-css-extract-plugin
export var siteTitle = "blog-module--site-title--EElEt";
export var bio = "blog-module--bio--sPeT9";
export var imgWrapper = "blog-module--img-wrapper--6mnZM";
export var bioWrapper = "blog-module--bio-wrapper--4-hk+";
export var textWrapper = "blog-module--text-wrapper--TaZX0";
export var socialWrapper = "blog-module--social-wrapper--YCjEl";
export var articles = "blog-module--articles--tJa8a";
export var article = "blog-module--article--fqe1U";
export var articleTitle = "blog-module--article-title--dVJZ9";
export var date = "blog-module--date--iYjN1";
export var tags = "blog-module--tags--V6hB6";
export var tag = "blog-module--tag--SpgSB";